import React, { useContext } from 'react'
import  './MiningFeatures.css'
import PAX from '../../../assets/logos/PAX.png';
import GoldenSpin from '../../../assets/images/GoldenSpin.png';
import FreeSpin from '../../../assets/images/FreeSpin.png';
import TimerIcon from '../../../assets/images/TimerIcon.png';
import NoLimit from '../../../assets/images/NoLimit.png';
import LiftTime from '../../../assets/images/LiftTime.png';
import OwnerShip from '../../../assets/images/OwnerShip.png';
import ManagersContext from 'Contexts/ManagersContext';
type MiningPropes = {
    type: string;
    type2?: string;
  };
function MiningFeatures({type,type2}: MiningPropes) {
    const {TimeframesMng } = useContext(ManagersContext);
  return (
    <>    
    {type == "Mining" && <div className='d-flex justify-content-around flex-wrap'>
      {type2!="ShowPaxBox" && <div className='RoundBox'
      style={{boxShadow:`${window.screen.width <767 ?"rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px":""}`}}
      >
            <div className='LogoDiv'>
                <img src={PAX} alt="" />
            </div>
            <p>PAX Tokens</p>
            <span>Gain tokens for every completed block (100 CMP), directly sent to your wallet.</span>
      </div>}
      <div className='RoundBox'
      style={{boxShadow:`${window.screen.width <767 ?"rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px":""}`}}
      >
            <div className='LogoDiv'>
                <img src={GoldenSpin} alt="" />
            </div>
            <p>Golden Spins</p>
            <span>Unlock 5x prizes on golden spins.</span>
      </div>
      <div className='RoundBox'
      style={{boxShadow:`${window.screen.width <767 ?"rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px":""}`}}
      >
            <div className='LogoDiv'>
                <img src={FreeSpin} alt="" />
            </div>
            <p>Spinners</p>
            <span>Get a free spinner every 3 hours to boost progress.</span>
      </div>
      <div className='RoundBox'
      style={{boxShadow:`${window.screen.width <767 ?"rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px":""}`}}
      >
            <div className='LogoDiv'>
                <img src={TimerIcon} alt="" />
            </div>
            <p>{TimeframesMng?.obj[0]?.seconds || 10}-Second Votes</p>
            <span>Vote faster for quicker mining completion.</span>
      </div>
    </div>}

    {type == "MVM" && <div className='d-flex justify-content-center flex-wrap'
    
    >
    <div className='TopImageBox'>
            <div className='BoxFoxImage'>
                <img src={LiftTime} alt="" />
                <div className='ShadowBox'
                style={{                    
                    background: "#F06892",
                    boxShadow: "0 0 60px 30px #F06892",
                }}      
                ></div>
            </div>
            <div className='BoxForText'>
                <p>Lifetime Benefits</p>
                <span>A one-time purchase for lifetime benefits.</span>
            </div>
      </div>  
      <div className='TopImageBox'>
            <div className='BoxFoxImage'>
                <img src={PAX} alt="" />
                <div className='ShadowBox'
                style={{
                    background: "#E2BC23",
                    boxShadow: "0 0 60px 30px #E2BC23",
                }}      
                ></div>
            </div>
            <div className='BoxForText'
            style={{boxShadow:`${window.screen.width <767 ?"rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px":""}`}}
            >
                <p>PAX Tokens</p>
                <span>Receive tokens for every block (100 CMP) you complete, sent directly to your wallet.</span>
            </div>
      </div>                        
      <div className='TopImageBox'>
            <div className='BoxFoxImage'>
                <img src={OwnerShip} alt="" />
                <div className='ShadowBox'
                style={{      
                                  
                    background: "#FFCE00",
                    boxShadow: "0 0 60px 30px #FFCE00",
                }}      
                ></div>
            </div>
            <div className='BoxForText'
            style={{boxShadow:`${window.screen.width <767 ?"rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px":""}`}}
            >
                <p>Digital Ownership</p>
                <span>100% ownership of all collectibles earned in the game.</span>
            </div>
      </div> 
      <div className='TopImageBox'>
            <div className='BoxFoxImage'>
                <img src={FreeSpin} alt="" />
                <div className='ShadowBox'
                style={{                    
                    background: "#C6CFF8",
                    boxShadow: "0 0 60px 30px #C6CFF8",
                }}      
                ></div>
            </div>
            <div className='BoxForText'
            style={{boxShadow:`${window.screen.width <767 ?"rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px":""}`}}
            >
                <p>Spinners</p>
                <span>Get a free spinner every 3 hours to boost progress.</span>
            </div>
      </div>         
      <div className='TopImageBox'>
            <div className='BoxFoxImage'>
                <img src={NoLimit} alt="" />
                <div className='ShadowBox'
                style={{                             
                    background: "#18A2FF",
                    boxShadow: "0 0 60px 30px #18A2FF",
                }}      
                ></div>
            </div>
            <div className='BoxForText'
            style={{boxShadow:`${window.screen.width <767 ?"rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px":""}`}}
            >
                <p>No Limits</p>
                <span>Enjoy permanent access to all mining benefits with no expiry.</span>
            </div>
      </div>      
    </div>}
    </>
  )
}

export default MiningFeatures
