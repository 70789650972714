import { VoteContextType } from "Contexts/VoteProvider";
import axios from "axios";
import { NotificationProps, UserProps } from "common/models/User";
import { VoteResultProps } from "common/models/Vote";
import { db, auth, firestore, functions } from "../../firebase";
import { collection, doc, getDoc, getDocs, limit, onSnapshot, orderBy, query, setDoc, where } from "firebase/firestore";
import { type, userInfo } from "os";
import React from "react";
import Swal from 'sweetalert2';
import moment from "moment";
import { showToast } from "App";
import { ToastType } from "Contexts/Notification";
import { httpsCallable } from "firebase/functions";

export type calculateDiffBetweenCoinsType = { firstCoin: string, secondCoin: string, difference: string };
export const calculateDiffBetweenCoins = (valueVotingTime: number[], valueExpirationTime: number[], direction: number) => {

    const firstCoin = (((valueExpirationTime[0] - valueVotingTime[0]) * 100) / valueVotingTime[0]);
    const secondCoin = (((valueExpirationTime[1] - valueVotingTime[1]) * 100) / valueVotingTime[1]);
    const difference = (direction === 0 ? (firstCoin) - (secondCoin) : (secondCoin) - (firstCoin))
    return {
        firstCoin: firstCoin.toFixed(4) || '0',
        secondCoin: secondCoin.toFixed(4) || '0',
        // @ts-ignore
        difference: difference != undefined && difference != "" && difference != "NaN" ? difference : '0'
    }
}

export const getCoinDifferenceColor = (value: number) => {
    return value < 0 ? '#fa0202' : value > 0 ? 'green' : '#050505'
}
export const getPairResultColor = (coin1Percentage: number, coin2Percentage: number, direction: number) => {
    if (direction) {
        return coin2Percentage > coin1Percentage ? 'green' : (coin1Percentage > coin2Percentage ? '#fa0202' : '#050505');
    } else {
        return coin1Percentage > coin2Percentage ? 'green' : (coin2Percentage > coin1Percentage ? '#fa0202' : '#050505');
    }
}


export const getCoinColor = (currentValue: number, prevValue: number) => {
    return currentValue < prevValue ? '#fa0202' : currentValue > prevValue ? 'green' : '#050505'
}
export const getSingleCoinPriceColor = (votingPrice: number, currentPrice: number, direction: number) => {
    //0 bull,1 bear , 1 price will increase 

    if (direction) {
        return votingPrice > currentPrice ? 'green' : votingPrice < currentPrice ? '#fa0202' : '#050505'
    } else {
        return votingPrice < currentPrice ? 'green' : votingPrice > currentPrice ? '#fa0202' : '#050505'
    }
}

export const scrollUp = () => {
    setTimeout(() => {
        window.scrollTo({
            top: 300,
            behavior: "smooth",
        });
    }, 2000);
}

export type cardDetailType = {
    id: string;
    cardName: string;
    cardId: string;
    quantity: number;
    albumId: string;
    cardStatus: string;
    totalQuantity: number;
    noOfCardHolders: number,
    cardImageUrl: string;
    cardVideoUrl: string;
    albumName: string;
    setId: string;
    cardType: string;
    setName: string;
    sno: string[]
}

export const divideArray = (arr: any, partSize: any) => {

    let divideByAlbum: { [key: string]: cardDetailType[] } = {};
    arr.map((value: any) => {
        divideByAlbum = { ...divideByAlbum, [value?.albumName]: (divideByAlbum[value?.albumName] ? [...divideByAlbum[value?.albumName], value] : [value]) }
    });
    let res1: cardDetailType[][] = [];
    Object.keys(divideByAlbum).map((albumName: string) => {
        let album = divideByAlbum[albumName];
        for (let i = 0; i < album.length; i += partSize) {
            res1 = [...res1, album.slice(i, i + partSize)];
        }
    });
    return res1;

}
export const divideArray1 = (arr: any, partSize: any) => {

    let divideByAlbum: { [key: string]: cardDetailType[] } = {};
    arr.map((value: any) => {
        divideByAlbum = { ...divideByAlbum, [value?.albumName]: (divideByAlbum[value?.albumName] ? [...divideByAlbum[value?.albumName], value] : [value]) }
    });
    let res1: { [key: string]: cardDetailType[][] } = {};
    Object.keys(divideByAlbum).map((albumName: string) => {
        let album = divideByAlbum[albumName];
        let albumWithSlice: cardDetailType[][] = [];
        for (let i = 0; i < album.length; i += partSize) {
            albumWithSlice = [...albumWithSlice, album.slice(i, i + partSize)];
        }
        res1 = { ...res1, [albumName]: albumWithSlice };
    });
    console.log(res1, 'divideByAlbum');
    return res1;

}

export const getPaginationData = (data: any[], pageIndex = 0, pageSize = 5) => {
    // page index start from 0
    return data.slice(pageSize * pageIndex, pageSize * (pageIndex + 1))
}

export const afterpaxDistributionToUser = (pax:number) => {
    axios.post("https://us-central1-votetoearn-9d9dd.cloudfunctions.net/updateAndGetPaxDistribution", {
        data:pax
    }).then((res) => {
        console.log(res.data,"afterpaxDistributionToUser")
    }).catch((err) => {
        console.log(err,"afterpaxDistributionToUser")        
    })
    
}


export function ReloadPop() {
    Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong! Please reload your page",
        confirmButtonText: "Reload page",
        allowOutsideClick: false
        // footer: '<a href="#">Why do I have this issue?</a>'
    }).then((result) => {
        if (result.isConfirmed) { 
            window.location.reload();
        }        
     });
}

export const UpdateLastLogin = async (user?:any) => {
    const lastLoginDay = new Date();
    const userRefUpdate = doc(db, "userStatistics",user?.uid);
    await setDoc(userRefUpdate, { lastLoginDay }, { merge: true });
}



export const CMPBasedOnVotes = async (userId:any) => {    
    const q = query(collection(db, "freeLuckySpin"), where("userId", "==",userId));
    const querySnapshot = await getDocs(q);

    // Extract data from the snapshot
    const freeSpinData = querySnapshot.docs.map(doc => ({
      id: doc.id, // Include the document ID if needed
      ...doc.data()
    }));

    const docRef = doc(db, "settings", "settings"); // Adjust 'setting' to your document ID
    const docSnap = await getDoc(docRef);
    const data = docSnap.data();
    const voteRules = (data?.voteRules?.CMPBasedOnVotes);    
    // @ts-ignore
    const CMPBasedOnVotes = freeSpinData[0]?.votesReceivedFromFirst4Spins ? freeSpinData[0].votesReceivedFromFirst4Spins : voteRules || 50;

    return CMPBasedOnVotes    
};


export const getGoldanVoteValue = async (userId:any,setGoldenValue:any) => {        
    onSnapshot(doc(db, "settings", "goldenVoteSettings"), (doc) => {
        setGoldenValue(doc.data());
      });   
};

export const getGoldanVoteResult = async (userId:any,voteId:any,setGoldenVoteCallStop:any) => {        
    const goldenVoteRewardDistribution = httpsCallable(functions, "goldenVoteRewardDistribution");
    const updateNotificationSeenForGoldenVote =httpsCallable(functions, "updateNotificationSeenForGoldenVote")
    const VoteResult = await goldenVoteRewardDistribution({userId,voteId}).then((result) => {                
        setGoldenVoteCallStop(false)
        updateNotificationSeenForGoldenVote({userId})
        return result?.data;
    }).catch((err) => {
        console.log(err,"VoteResultVoteResult")
    });
    // console.log(VoteResult,VoteResult?.data,"VoteResultVoteResult")
    // @ts-ignore
    return VoteResult
    
};

export const MakeResultPopupTable= async (request:any,coin:any,coin1:any,coin2:any,userInfo:any)=>{
    const CoinNames = window?.location?.pathname?.split("/")[2].split("-");          
      if(CoinNames[0] == coin1.toUpperCase() && (CoinNames[1] !==undefined?CoinNames[1] == coin2?.toUpperCase():coin2 == "")){
          let scorevalue = 0 
        const CMPBasedOnVotesValue = await CMPBasedOnVotes(userInfo?.uid)           
        const docRef = doc(db, "votes", request.voteId);
        const docSnap = await getDoc(docRef);
        const voteData= docSnap.data();
      
        if (userInfo && userInfo?.voteStatistics && userInfo?.voteStatistics?.total <= CMPBasedOnVotesValue && voteData?.status?.name === "Member") {
          const getScoreValue = await forNewUseCmp(
            request?.status || 0,                        
            voteData?.multiplier
          );
          scorevalue = getScoreValue;
        } else {
          const getScoreValue = await forOldUserCmp(
            request?.status || 0,            
            voteData?.status,
            voteData?.multiplier
          );
          console.log(getScoreValue,"getScoreValue")
          scorevalue = getScoreValue;
        }
        
        return scorevalue;

        // const response = {
        //   data:{
        //     ...docSnap.data(),
        //     score: scorevalue,
        //     success:request.status,
        //     valueExpirationTime:voteData?.voteType == "coin" ?`${request.valueExpirationTimeOfCoin1}`:[request.valueExpirationTimeOfCoin1,request.valueExpirationTimeOfCoin2],
        //     paxDistribution: null,
        //   }
        // }            
                    
          }
}

export const forNewUseCmp=async (success: number,multiplier: number) => {
    
    const docRef = doc(db, "settings", "settings"); // Adjust 'setting' to your document ID
    const docSnap = await getDoc(docRef);
    const data = docSnap.data();
    const voteRules = (data?.voteRules);

  let CPMReturn: number;
  if (success === 1) {
    CPMReturn = voteRules.CMPRangeOnDefault50Votes
      ? Number(voteRules.CMPRangeOnDefault50Votes.high)
      : 2;
  } else if (success === 2) {
    CPMReturn = voteRules.CMPRangeOnDefault50Votes
      ? Number(voteRules.CMPRangeOnDefault50Votes.medium)
      : 1.5;
  } else {
    CPMReturn = voteRules.CMPRangeOnDefault50Votes
      ? Number(voteRules.CMPRangeOnDefault50Votes.low)
      : 1;
  }

  CPMReturn = CPMReturn * (multiplier || 1);
  return CPMReturn;
};



export const forOldUserCmp = async(success: number, status: any, multiplier: number)=> {
    
    const docRef = doc(db, "settings", "settings"); // Adjust 'setting' to your document ID
    const docSnap = await getDoc(docRef);
    const data = docSnap.data();
    const voteRules = (data?.voteRules);
    
    console.log(voteRules,success,multiplier,"voteRulesvoteRules")
  let CPMReturn: number;
  if (success === 1) {
    CPMReturn = (Number(status?.givenCPM) || 1) * Number(voteRules.CPMReturnSuccess) * multiplier;
  } else if (success === 2) {
    CPMReturn = (Number(status?.givenCPM) || 1) *  Number(voteRules.CPMReturnInRange) * multiplier;
  } else {
    CPMReturn = (Number(status?.givenCPM) || 1) * Number(voteRules.CPMReturnFailure) * multiplier;
  }  
  return (Number(voteRules?.givenCPM) || 1) * CPMReturn;
};




export const addCmpByFE =async (userInfo:any,cmpValue:number)=>{
    const docRef = doc(db, "users", `${userInfo?.uid}`); 
    const docSnap = await getDoc(docRef);
    const UpdatedUserInfo = docSnap.data();    

    const getCurrentScore =Number(UpdatedUserInfo?.voteStatistics?.score || 0) - Number(UpdatedUserInfo?.rewardStatistics?.total || 0) * 100;
            const rewardData = UpdatedUserInfo?.rewardStatistics;
            const voteStatisticsData = UpdatedUserInfo?.voteStatistics;
            console.log(voteStatisticsData,"voteStatisticsData112212")
          // @ts-ignore
          rewardData.total  = (getCurrentScore + cmpValue) > 200 ? Number(UpdatedUserInfo?.rewardStatistics?.total) + 2:(getCurrentScore + cmpValue) > 100 ? Number(UpdatedUserInfo?.rewardStatistics?.total) + 1 :Number(UpdatedUserInfo?.rewardStatistics?.total);          
          voteStatisticsData.score = Number(voteStatisticsData.score) + cmpValue;          
        console.log(rewardData.total , voteStatisticsData.score,"voteStatisticsData.score")
          // @ts-ignore
          const userDocRef = doc(firestore, "users", userInfo?.uid);
          try {
            setDoc(
              userDocRef,              
              { voteStatistics: voteStatisticsData },
              { merge: true }
            );            
            setTimeout(() => {
                setDoc(
                    userDocRef,
                    { rewardStatistics: rewardData },              
                    { merge: true }
                  );                   
            }, 400);         
          } catch (error) {
            console.error("Error updating user data:", error);
          }
          return true;
    }
export  const getGMTTimestamp = () => {
        // Get the current moment in UTC
          const now = moment().utc();

            // Add 1 day and set the time to 04:00:00
            const nextDay = now
                .add(1, 'day') // Move to the next day
                .set({ hour: 4, minute: 0, second: 0, millisecond: 0 });

            // Get the timestamp in milliseconds
            return nextDay.valueOf();
      
        return nextDay.valueOf();
      };


      export const getGMTTimestampWithValue = (value:number) => {
        // Create a moment object for today
        const today = moment().utc(); // Ensure it is in UTC/GMT
        const gmtTimestamp = today
          .set({ hour: value, minute: 0, second: 0, millisecond: 0 }) // Set time to 04:00
          .valueOf(); // Get the timestamp in milliseconds
      
        return gmtTimestamp;
      };
      

      export const getNewGoldVtoe = (value:any): Date => {
        const now = new Date();
        const target = new Date(now);
        target.setUTCHours(value, 0, 0, 0); // Set to 18:00 UTC
      
        if (now.getTime() > target.getTime()) {
          // If current time is past 18:00 UTC, set target to tomorrow 18:00 UTC
          target.setDate(target.getDate() + 1);
        }
      
        return target;
      };

export const getAllNotifications = async (userId: any, setNotifications:any, limitNumber:number) => {    
    const q = query(
        collection(db, 'notifications'),
        where('user', '==', userId || ''),
        orderBy('time', 'desc'),
        limit(limitNumber)
    );
    
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const data:any = [];
        // setNotificationCount((prev:any) => prev + 1)
        querySnapshot.forEach((doc) => {
            data.push({ notificationId:doc?.id, ...doc.data() });
        });        
        setNotifications(data as NotificationProps);   
    });    
    return unsubscribe;
};


export const getGoldenVoteValue = async (userId:any) => {
    try {
        const voteCollection = collection(db, "votes");
        const q = query(
          voteCollection,
          where("userId", "==", userId),
          where("isGoldenVote", "==", true),
          orderBy("voteTime", "desc"),
          limit(1)
        );
        
        const querySnapshot = await getDocs(q);        
        const doc = querySnapshot.docs[0];
        const VoteValue  = {
            id: doc.id,
            ...doc.data(),
        };
        return VoteValue
    
      } catch (error) {
        console.error("Error fetching latest golden vote: ", error);
      }
}

export const GetVoteDetelis = async (voteId:any) => {
    console.log(voteId,"voteIdvoteId")    
const docRef = doc(db, "votes", voteId);    
try {
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const { multiplier } = docSnap.data();      
      return multiplier;
    } else {
      console.log('No such document!');
      return null;
    }
  } catch (error) {
    console.error("Error fetching document: ", error);
  }
};

export const cardTradNotificationsCount = async (userId: any, setNotificationCount: any, limitNumber:number) => {    
    const q = query(
        collection(db, 'notifications'),
        where('user', '==', userId || ''),
        where('notificationSeen', '==', false),
        // orderBy('time', 'desc'),
        limit(limitNumber)
    );
    
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const data:any = [];
        querySnapshot.forEach((doc) => {
            data.push({ ...doc.data() });
        });        
        setNotificationCount(data?.length)
        // setNotifications(data as NotificationProps);   
    });    
    return unsubscribe;
};



export const fetchCurrentTime = async()=> {
        try {
            const response = await fetch('http://worldtimeapi.org/api/timezone/Etc/UTC');
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            console.log(data,"getTimezone")
            const realtime = new Date(data.utc_datetime).getTime();        
            console.log(realtime,"getTimezonerealtime")
        } catch (error) {
            console.error('Failed to fetch the current time:', error);
            return Date.now(); // Fallback to local time
        }
};


export const getPriceFirstTime = async (coins:any, setCoins:any)=> {
    console.log('Attempting to reconnect...');
    // @ts-ignore
    if (coins) {                
        const coinTikerList = Object.keys(coins)?.map(item => `${item}`)
        const afterErrorPrice = {}
        try {
            const response = await axios.get(`https://min-api.cryptocompare.com/data/pricemulti?fsyms=${coinTikerList.join(',')}&tsyms=USD`);
            Object.keys(response.data)?.map((symblaName) => {
                Object.assign(afterErrorPrice,
                    {
                        [symblaName]: {
                            id: coins[symblaName]?.id,
                            name: coins[symblaName]?.name,
                            price: response.data[symblaName]?.USD,
                            symbol: symblaName,
                            trend: coins[symblaName]?.trend

                        }
                    }
                )
                setCoins(afterErrorPrice)
                localStorage.setItem('CoinsPrice', JSON.stringify(afterErrorPrice));
            })
            return afterErrorPrice
        } catch (error) {
            console.error('Error fetching data:', error);
            localStorage.setItem('CoinsPrice', JSON.stringify(coins));
        }
    }    
}
const getUniqueRandomValues=()=> {
    const chars = 'abcdefghijklmnopqrstuvwxyz0123456789';
    let values = '';

    while (values.length < 4) {
        const randomChar = chars.charAt(Math.floor(Math.random() * chars.length));
        if (!values.includes(randomChar)) {
            values += randomChar;
        }
    }

    return values;
}

export const payWithVTE = (user: any, payamount: any, isLoading: any, getSpinData: any, setIsLoading: any) => {
    setIsLoading(true);
    const headers = {        
        "accept": "application/json",
        // @ts-ignore
        "Authorization": `Bearer ${auth?.currentUser?.accessToken}`,
        "content-type": "application/json"
    }    
    const data = {
        userId: `${user?.uid}`,
        userEmail: `${user?.email}`,
        walletType: `wallet connect`,
        amount: Number(0),
        dollarAmount: Number(0),
        VTEAmount: Number(payamount),
        // amount: 0.0001,
        // @ts-ignore
        network: "",
        // @ts-ignore
        origincurrency: "",
        token:"VTE",
        event: 'Approved',
        transactionType:"MEGASPIN",
        numberOfVotes: 0,
        paymentDetails: {
            orderId: `VTE-ME-${getUniqueRandomValues()}`,
        },
        miningPassDurationInDays:0,

    }    
    axios.post(`${process.env.REACT_APP_API}/payment/update/user/afterPayment`, data,
        {
            headers: headers
        }).then((result) => {
            setIsLoading(false);
            getSpinData(user?.uid)
        }).catch((err) => {
            setIsLoading(false);   
            showToast((err as Error).message, ToastType.ERROR);
        });
}


export const getUserNameDB = async (userId: any) => {
    const q = query(
        collection(db, 'users'),
        where('uid', '==', userId),
        limit(1)
    );
    const usersSnapshot = await getDocs(q);
    const existingUsernames = usersSnapshot.docs.map((doc:any) => doc.data());
    const isUsernameTaken = await existingUsernames[0].userName;
    return isUsernameTaken

};

export const getUserData = async (userId: any) => {
    const q = query(
        collection(db, 'users'),
        where('uid', '==', userId),
        limit(1)
    );
    const usersSnapshot = await getDocs(q);
    const existingUsernames = usersSnapshot.docs.map((doc:any) => doc.data());
    const isUsernameTaken = await existingUsernames[0]
    return isUsernameTaken

};

export const convertTime = (timestamp: any) => {
    const milliseconds = (timestamp._seconds * 1000) + (timestamp._nanoseconds / 1000000);
    const date = moment(milliseconds);
    return date.format('YYYY-MM-DD HH:mm:ss'); // Adjust format as needed
}
export const BuySpin = async () => {
    return await Swal.fire({
        title: 'Buy Mega Spin',
        text: '50 VTE will be deducted from your balance.',
        // icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#6352e8',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        customClass: {
            popup: 'custom-swal-popup'
        }
    });
};

export const telegramLoginData = async (queryString:any) => {
    let params = new URLSearchParams(queryString);

    // Decode and parse 'user' parameter
    let userEncoded = params.get('user');
    // @ts-ignore
    let userDecoded = JSON.parse(decodeURIComponent(userEncoded));

    // Extract other needed parameters
    let authDate = params.get('auth_date');
    let hash = params.get('hash');

    // Construct the object
    let result = {
        id: userDecoded.id,
        first_name: userDecoded.first_name,
        secret: process.env.REACT_APP_TELEGRAM_LOGIN_SECRET_KEY,
        auth_date: Number(authDate),
        hash: hash,
    };
    return result
};
export const confirmDelete = async () => {
    return await Swal.fire({
        title: 'Cancel offer',
        text: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#6352e8',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
    });
};
export const confirmApproved = async () => {
    return await Swal.fire({
        title: 'Approve offer',        
        text: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#6352e8',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
    });
};
export const confirmReject = async () => {
    return await Swal.fire({
        title: 'Reject offer',
        text: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#6352e8',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
    });
};

export const confirmApprovedGift = async () => {
    return await Swal.fire({
        title: 'Approve Gift',
        text: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#6352e8',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
    });
};
export const confirmRejectGift = async () => {
    return await Swal.fire({
        title: 'Reject Gift',
        text: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#6352e8',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
    });
};
export const confirmDeleteGift = async () => {
    return await Swal.fire({
        title: 'Cancel Gift',
        text: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#6352e8',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        cancelButtonText: 'No'
    });
};

export const getCurrentDateTime = (timestampValue?: any) => {
    
    let now;
    if (timestampValue) {
        now = new Date(timestampValue);
    } else {
        now = new Date();
    }

    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    return `${year}-${month}-${day}T${hours}:${minutes}`;
};


export const handleValidation = (event:any) => {
    const selectedDateTime = new Date(event.target.value);
    const currentDateTime = new Date();

    if (selectedDateTime < currentDateTime) {
        alert('You cannot select a past date and time.');
        console.log("121212121221212 worng date pike")
        event.preventDefault();
        return false;
    }
    else {
        console.log("121212121221212 right date pike")
    }
    return true;
};

export const getCurrentDate = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};

export const getCurrentTime = () => {
    const now = new Date();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
};


export const getSerchByUserName = async (userName: any) => {
    const q = query(
        collection(db, 'users'),
        where('userName', '==', userName),
        limit(1)
    );
    const usersSnapshot = await getDocs(q);
    const existingUsernames = usersSnapshot.docs.map((doc:any) => doc.data());
    const isUsernameTaken = await existingUsernames;
    return isUsernameTaken

};

// export const getCardNameById = async (cardId: any) => {
//     const q = query(
//         collection(db, 'cardsDetails'),
//         where('cardId', '==', cardId),
//         limit(1)
//     );
//     const usersSnapshot = await getDocs(q);
//     const existingUsernames = usersSnapshot.docs.map((doc:any) => doc.data());
//     const isUsernameTaken = await existingUsernames[0]?.cardName;
//     console.log(isUsernameTaken,"isUsernameTaken")
//     return isUsernameTaken
// };

export const removeVote = (
    voteDetails: VoteContextType,
    setVoteDetails: React.Dispatch<React.SetStateAction<VoteContextType>>,
    setAfterVotePopup: any,
    setCompletedVotes: React.Dispatch<React.SetStateAction<VoteResultProps[]>>,
    setLessTimeVoteDetails: React.Dispatch<React.SetStateAction<VoteResultProps | undefined>>,
    userInfo: UserProps | undefined,
) => {
    let temp = {};
    setCompletedVotes(prev => prev.filter(value => value.voteId != voteDetails?.lessTimeVote?.voteId));
    setVoteDetails((prev) => {
        Object.keys(prev?.activeVotes).map((key: string) => {
            if (voteDetails?.lessTimeVote?.voteId !== prev?.activeVotes[key].voteId) {
                temp = { ...temp, [`${prev?.activeVotes[key].coin}_${prev?.activeVotes[key]?.timeframe?.seconds}`]: prev?.activeVotes[key] }
            }
        });
        return {
            ...prev,
            lessTimeVote: undefined,
            activeVotes: temp,
            openResultModal: false,
        };
    });
    if (Object.keys(temp)?.length <= 0 && (Number(userInfo?.voteValue || 0) + Number(userInfo?.rewardStatistics?.extraVote || 0)) <= 0) {
        setAfterVotePopup(true);
    }

    setLessTimeVoteDetails(undefined);
}

export const findMatchingValues = (body:string,navigate:any)=> {
    const coinList=["PEPE-FLOKI","ETH-BNB","XRP-ADA","BTC-ETH",
        "DOGE-SHIB","SAND-MANA","DOT-MATIC","UNI-CAKE","LTC-XRP","LINK-TRX","ADA-SOL","CRO-BNB","BTC","PEPE","FLOKI","ETH","BNB","ADA","SOL","XRP","DOGE","DOT","SHIB","MATIC","CRO","LTC",
        "LINK","UNI","TRX","XLM","MANA","HBAR","VET","SAND","EOS","CAKE","TRUMP"
    ]  
    const UpperCaseString = body.toUpperCase();
      
    const matches = coinList.find((item:any) => UpperCaseString.includes(item.toUpperCase()));
    if(matches?.includes("-")){
        navigate(`/pairs/${matches}`)      
    }else{
        navigate(`/coins/${matches}`)
    }
  }


export const NotificationRedirect = ((notificationData:any,setTradeHistoryShow:any ,setTradeHistoryTab:any,navigate:any)=>{
    const title = notificationData?.message?.title
    const Body = notificationData?.message?.body
    if(title.includes("Mining Pass Activated")|| title.includes("Mining Pass Expired")){
        navigate('/upgrade');
      }
      if(title.includes("Golden Vote Now Available")|| title.includes("coin price ALERT!") || title.includes("Golden Vote: Limited Time!")){
        // navigate('/spin');
        findMatchingValues(Body,navigate)
    }
    if(title.includes("just voted for")){
        // navigate('/spin');
        findMatchingValues(title,navigate)
    }
    if(title.includes("Limited Time Offer! Boost Your Collection Now!")|| title.includes("Congratulations! Your Card Purchase is Complete!")|| title.includes("Time's Up!")){
          navigate('/profile/CollectiblesAlbum');
      }
      if (title === "Congratulations! Your Card Purchase is Complete!" || title.includes("Trade Rejection Alert") || title === "Congratulations! Your Card Deal is Complete!") {
        navigate('/profile/trade'); 
        setTradeHistoryShow('TradeHistory')
        setTradeHistoryTab(1)
    }  
      if (title === "Congratulations! You've Received a Gift!" || title === "Congratulations! Your Gift Has Been Approved!") {
        navigate('/profile/trade'); 
        setTradeHistoryShow('TradeHistory')
        setTradeHistoryTab(2)
      }
  
      if (title === "Trade Negotiation Alert!") {
        navigate(`/profile/trade?tabID=3`);
      }
      if (title === "Spin to win") {
        navigate(`/wheel`);
      }
      if (title.includes("It's Time to Make Your Voice Heard Again!")) {
        navigate(`/coins`);
      }
      if (title.includes("Add PAX address") || title.includes("Pax pending")|| title.includes("Pax Received")) {
        navigate(`/profile/wallet`);
      }
      if (title.includes("You just earnd") || title === "Vote to earn more!" || title === "Keep Voting to Rise Again!" || title === "Wow") {
        navigate(`/profile/mine`);
      }      
      if (title== "Congratulations! You've Received Your Golden Vote Reward!") {
        if(Body.includes("Spin")){
            navigate(`/wheel`);
        }
        if(Body.includes("VTE")){
            navigate(`/profile/mine`);
        }
        if(Body.includes("Card")){
            navigate(`/profile/CollectiblesAlbum`);
        }
        if(Body.includes("Mining pass")){
            navigate(`/upgrade`);
        }
        if(Body.includes("Extra Votes")){
            navigate(`/coins`);
        }
        if(Body.includes("Extra Votes")){
            navigate(`/coins`);
        }
      }      
})